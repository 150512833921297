//
//
//
//
//

import GlobalMixin from '../shared/GlobalMixins.js'
import GamePlayMixin from "../shared/GamePlayMixin";

export default {
  name: 'GamePlay',
  mixins: [GlobalMixin, GamePlayMixin],
  data () {
    return {
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (!vm.loggedIn) {
        next({ name: 'login' })
      }
      if (to !== 'home') vm.setGameExited(false);
    })
  },
  computed: {
    currentGame () {
      // format url slug as pseudo camel-case for directories
      let game_id = this.$route.params.game

      if (typeof game_id !== 'undefined') {
        game_id = game_id.replace(/-([a-z])/g, function (g) { return g[1].toUpperCase(); })
        return game_id.charAt(0).toUpperCase() + game_id.slice(1)
      }
    },
    gameComponent() {
			return () => import(`../components/GamePlay/Games/${this.currentGame}/Game.vue`);
		}
  },
  mounted () {

  }
}
